import $ from 'jquery'
const  $doc = $(document)


$doc.ready( ()=>{
  // set hidden field to be the team member's modal contact form that was opened.
  let popupWrappers = document.querySelectorAll('.modal-noshow');
  popupWrappers.forEach((popup) => {
    let teamMember = popup.dataset.teammember;
    let hiddenField = popup.querySelector('input[name="item_meta[25]"');
    if(hiddenField) hiddenField.value = teamMember;
  });

});
